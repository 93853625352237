import { AppConstants } from '../../app.constants';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    /*
     * Used to get data in storage
     */
    public async get(key: string): Promise<any> {
        try {
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: this.getNameSpace(key) });
                return value ? JSON.parse(value) : null;
            } else {
                const item = localStorage.getItem(this.getNameSpace(key));
                return item ? JSON.parse(item) : null;
            }
        } catch (error) {
            console.error(`Error getting data from storage for key ${key}:`, error);
            throw error;
        }
    }

    /*
     * Used to get all data in storage
     */
    public async fetchAll(): Promise<any> {
        try {
            const preferences: { [key: string]: any } = {};
            if (Capacitor.isNativePlatform()) {
                const { keys } = await Preferences.keys();
                for (const key of keys) {
                    const { value } = await Preferences.get({ key });
                    preferences[key] = value;
                }
            }
            return preferences;
        } catch (error) {
            console.error(`Error getting all data from storage:`, error);
            throw error;
        }
    }

    /*
     * Used to set data in storage
     */
    public async set(key: string, value: any): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            return await Preferences.set({ key: this.getNameSpace(key), value: JSON.stringify(value) });
        } else {
            return localStorage.setItem(this.getNameSpace(key), JSON.stringify(value));
        }
    }

    /*
     * Used to clear all data in storage
     */
    public async clear(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            return await Preferences.clear();
        } else {
            return localStorage.clear();
        }
    }

    /*
     * Used to clear specific data entry in storage
     */
    public async remove(key: string): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            return await Preferences.remove({ key: this.getNameSpace(key) });
        } else {
            return localStorage.removeItem(this.getNameSpace(key));
        }
    }

    /*
     * Used to construct the namespace for storage
     */
    private getNameSpace(key: string): string {
        return `${AppConstants.STORAGE_PREFIX}_${key}`;
    }
}
