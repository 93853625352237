import { AppConstants } from '../../app.constants';
import { AppPages } from 'src/app/app.pages';
import { AuthCredentials } from '@interfaces/auth-credentials.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { firstValueFrom, map } from 'rxjs';
import { getApiUrl } from '@shared/helpers/get-api-url';

@Injectable({
    providedIn: 'root'
})
/**
 * AuthService is responsible for the authentication process and managing the client token.
 */
export class AuthService {
    private _token: string | null = null;
    private readonly loadingController: LoadingController = inject(LoadingController);
    private readonly http: HttpClient = inject(HttpClient);
    private readonly router: Router = inject(Router);
    private readonly storageService: StorageService = inject(StorageService);

    /**
     * Getter method to retrieve token
     */
    public get token(): string | null {
        return this._token;
    }

    /**
     * Setter method to set token
     */
    public set token(value: string) {
        this._token = value;
    }

    /**
     * Restore token from local storage
     */
    public async restoreToken(): Promise<void> {
        this._token = await this.storageService.get(AppConstants.USER_TOKEN);
    }

    /**
     * Retrieve data from token
     */
    public decodeToken(token: string): any {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    /**
     * Sends credentials of the client to the API.
     */
    public async login(credentials: AuthCredentials): Promise<void> {
        const { token } = await firstValueFrom(
            this.http.post(getApiUrl('/login'), credentials).pipe(map((response: any) => response.data))
        );
        if (token) {
            this._token = token;
            await this.storageService.set(AppConstants.USER_TOKEN, token);
        } else {
            throw new Error('Api did not return token');
        }
    }

    /**
     * Inform application that user has logged and updated
     */
    public async logout(timeout?: number): Promise<void> {
        this._token = null;
        await this.storageService.clear();
        this.router.navigateByUrl(AppPages.Login);
        if (await this.loadingController.getTop()) {
            this.loadingController.dismiss();
        }
        setTimeout(() => {
            window.location.reload();
        }, timeout || 500);
    }
}

export const authFactory = (authService: AuthService) => {
    return async (): Promise<void> => await authService.restoreToken();
};
